// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projekty-grant-3-js": () => import("./../../../src/pages/projekty/grant-3.js" /* webpackChunkName: "component---src-pages-projekty-grant-3-js" */),
  "component---src-pages-projekty-lowe-do-pobrania-js": () => import("./../../../src/pages/projekty/lowe-do-pobrania.js" /* webpackChunkName: "component---src-pages-projekty-lowe-do-pobrania-js" */),
  "component---src-pages-projekty-lowe-js": () => import("./../../../src/pages/projekty/lowe.js" /* webpackChunkName: "component---src-pages-projekty-lowe-js" */),
  "component---src-pages-projekty-lowe-oferta-js": () => import("./../../../src/pages/projekty/lowe-oferta.js" /* webpackChunkName: "component---src-pages-projekty-lowe-oferta-js" */),
  "component---src-pages-projekty-lowe-rekrutacja-js": () => import("./../../../src/pages/projekty/lowe-rekrutacja.js" /* webpackChunkName: "component---src-pages-projekty-lowe-rekrutacja-js" */),
  "component---src-pages-przedszkole-deklaracja-dostepnosci-js": () => import("./../../../src/pages/przedszkole/deklaracja-dostepnosci.js" /* webpackChunkName: "component---src-pages-przedszkole-deklaracja-dostepnosci-js" */),
  "component---src-pages-przedszkole-dokumenty-js": () => import("./../../../src/pages/przedszkole/dokumenty.js" /* webpackChunkName: "component---src-pages-przedszkole-dokumenty-js" */),
  "component---src-pages-przedszkole-dozywianie-js": () => import("./../../../src/pages/przedszkole/dozywianie.js" /* webpackChunkName: "component---src-pages-przedszkole-dozywianie-js" */),
  "component---src-pages-przedszkole-galeria-js": () => import("./../../../src/pages/przedszkole/galeria.js" /* webpackChunkName: "component---src-pages-przedszkole-galeria-js" */),
  "component---src-pages-przedszkole-index-js": () => import("./../../../src/pages/przedszkole/index.js" /* webpackChunkName: "component---src-pages-przedszkole-index-js" */),
  "component---src-pages-przedszkole-kontakt-js": () => import("./../../../src/pages/przedszkole/kontakt.js" /* webpackChunkName: "component---src-pages-przedszkole-kontakt-js" */),
  "component---src-pages-przedszkole-pracownicy-js": () => import("./../../../src/pages/przedszkole/pracownicy.js" /* webpackChunkName: "component---src-pages-przedszkole-pracownicy-js" */),
  "component---src-pages-przedszkole-rekrutacja-js": () => import("./../../../src/pages/przedszkole/rekrutacja.js" /* webpackChunkName: "component---src-pages-przedszkole-rekrutacja-js" */),
  "component---src-pages-przedszkole-rodo-js": () => import("./../../../src/pages/przedszkole/rodo.js" /* webpackChunkName: "component---src-pages-przedszkole-rodo-js" */),
  "component---src-pages-przedszkole-statut-js": () => import("./../../../src/pages/przedszkole/statut.js" /* webpackChunkName: "component---src-pages-przedszkole-statut-js" */),
  "component---src-pages-szkola-podstawowa-deklaracja-dostepnosci-js": () => import("./../../../src/pages/szkola-podstawowa/deklaracja-dostepnosci.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-deklaracja-dostepnosci-js" */),
  "component---src-pages-szkola-podstawowa-dokumenty-do-pobrania-js": () => import("./../../../src/pages/szkola-podstawowa/dokumenty-do-pobrania.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-dokumenty-do-pobrania-js" */),
  "component---src-pages-szkola-podstawowa-dozywianie-js": () => import("./../../../src/pages/szkola-podstawowa/dozywianie.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-dozywianie-js" */),
  "component---src-pages-szkola-podstawowa-galeria-js": () => import("./../../../src/pages/szkola-podstawowa/galeria.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-galeria-js" */),
  "component---src-pages-szkola-podstawowa-historia-szkoly-js": () => import("./../../../src/pages/szkola-podstawowa/historia-szkoly.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-historia-szkoly-js" */),
  "component---src-pages-szkola-podstawowa-index-js": () => import("./../../../src/pages/szkola-podstawowa/index.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-index-js" */),
  "component---src-pages-szkola-podstawowa-kalendarz-szkolny-js": () => import("./../../../src/pages/szkola-podstawowa/kalendarz-szkolny.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-kalendarz-szkolny-js" */),
  "component---src-pages-szkola-podstawowa-kontakt-js": () => import("./../../../src/pages/szkola-podstawowa/kontakt.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-kontakt-js" */),
  "component---src-pages-szkola-podstawowa-plan-lekcji-js": () => import("./../../../src/pages/szkola-podstawowa/plan-lekcji.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-plan-lekcji-js" */),
  "component---src-pages-szkola-podstawowa-pracownicy-js": () => import("./../../../src/pages/szkola-podstawowa/pracownicy.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-pracownicy-js" */),
  "component---src-pages-szkola-podstawowa-regulaminy-i-procedury-js": () => import("./../../../src/pages/szkola-podstawowa/regulaminy-i-procedury.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-regulaminy-i-procedury-js" */),
  "component---src-pages-szkola-podstawowa-rekrutacja-js": () => import("./../../../src/pages/szkola-podstawowa/rekrutacja.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-rekrutacja-js" */),
  "component---src-pages-szkola-podstawowa-rodo-js": () => import("./../../../src/pages/szkola-podstawowa/rodo.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-rodo-js" */),
  "component---src-pages-szkola-podstawowa-sponsorzy-js": () => import("./../../../src/pages/szkola-podstawowa/sponsorzy.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-sponsorzy-js" */),
  "component---src-pages-szkola-podstawowa-statut-js": () => import("./../../../src/pages/szkola-podstawowa/statut.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-statut-js" */),
  "component---src-pages-szkola-podstawowa-wykaz-podrecznikow-js": () => import("./../../../src/pages/szkola-podstawowa/wykaz-podrecznikow.js" /* webpackChunkName: "component---src-pages-szkola-podstawowa-wykaz-podrecznikow-js" */),
  "component---src-templates-gallery-template-js": () => import("./../../../src/templates/gallery-template.js" /* webpackChunkName: "component---src-templates-gallery-template-js" */)
}

